import Vue from 'vue';

import '@/styles/overrides.sass'
import '@/styles/_text.scss'

import Vuetify from 'vuetify/lib/framework';
import {
	VApp,
	VAppBarNavIcon,
	VAlert,
	VBreadcrumbs,
	VBtn,
	VCard,
	VDialog,
	VDivider,
	VExpansionPanel,
	VIcon,
	VImg,
	VInput,
	VLabel,
	VList,
	VListGroup,
	VListItem,
	VListItemAction,
	VMenu,
	VCardTitle,
	VCardText,
	VCardSubtitle,
	VCardActions,
	VSpacer,
	VToolbarTitle,
	
	VRow,
	VCol,
	VContainer,
} from 'vuetify/lib/index';

Vue.use(Vuetify, {
	components: {
		VApp,
		VAppBarNavIcon,
		VAlert,
		VBreadcrumbs,
		VBtn,
		VCard,
		VDialog,
		VDivider,
		VExpansionPanel,
		VIcon,
		VImg,
		VInput,
		VLabel,
		VList,
		VListGroup,
		VListItem,
		VListItemAction,
		VMenu,
		
		VCardTitle,
		VCardText,
		VCardSubtitle,
		VCardActions,
		
		VSpacer,
		VToolbarTitle,
		
		VRow,
		VCol,
		VContainer,
	}
});

const theme = {
	primary: '#18b3b3', // '#e91e63'
	secondary: '#1867c0',
	accent: '#9C27b0',
	info: '#00CAE3',
	muted: '#6c757d'
}

export default new Vuetify({
   theme: {
       themes: {
           dark: theme,
           light: theme,
       },
   },
});
