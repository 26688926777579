import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

/**
 * import vueMeta
 */
import VueMeta from 'vue-meta'

Vue.use(VueMeta);

/**
 * import LoadScript support
 */
import LoadScript from 'vue-plugin-load-script';

Vue.use(LoadScript);

/**
 * import cookies
 */
import VueCookies from 'vue-cookies';

Vue.use(VueCookies);

/**
 * Import screen
 */
import VueScreen from 'vue-screen';

Vue.use(VueScreen);

/**
 * import lodash
 */
import VueLodash from 'vue-lodash'
import lodash from 'lodash'

Vue.use(VueLodash, {name: '_lodash', lodash: lodash});

/**
 * import axios
 */
import axios from 'axios'

let M360BaseUrl = process.env.VUE_APP_M360_GATEWAY;
const base = axios.create({
	baseURL: M360BaseUrl,
	headers: {'Content-Type': 'application/json'}
});
Vue.prototype.$http = base;

const DynamicAbstract = Vue.component('dynamic-abstract', {
	props: {
		context: {
			type: Object
		}
	},
	created() {
		//check attach methods
		this.context.methods = {};
		if (this.context.properties.methods) {
			for (let i in this.context.properties.methods) {
				this.context.methods[i] = new Function(this.context.properties.methods[i].arguments, this.context.properties.methods[i].logic);
			}
		}
		
		//check attach computed values
		this.context.computed = {};
		if (this.context.properties.computed) {
			for (let i in this.context.properties.computed) {
				this.context.computed[i] = new Function(this.context.properties.computed[i]);
			}
		}
	},
	render(e) {
		const _self = this;
		const render = {
			methods: _self.context.methods,
			computed: _self.context.computed,
			data() {
				return {
					data: _self.context.properties.data
				}
			},
			template: _self.context.properties.template
		}
		return e(render);
	}
});

new Vue({
	router,
	store,
	vuetify,
	render: h => h(App),
	beforeCreate() {
		store.dispatch('init');
	},
	components: {
		DynamicAbstract
	}
}).$mount('#app')

Vue.directive('init', {
	bind: function (el, binding, vnode) {
		if (vnode.context[binding.expression] && typeof vnode.context[binding.expression] === 'function') {
			vnode.context[binding.expression]();
		}
	}
});