/*
 * Copyright (C) 2021 Corsair M360, Inc - All Rights Reserved.
 *  Unauthorized copying of this file, via any medium is strictly prohibited.
 *  Proprietary and confidential.
 */

let routes = [
	{
		path: '/error404',
		name: 'error404',
		index: 2,
		component: () => import('@/views/template/Error')
	},
	{
		path: '',
		component: () => import('@/views/template/Index'),
		children: [
			{
				path: '/',
				name: 'HomePage',
				component: () => import('@/views/template/DynamicPage')
			},
			{
				path: '/:page/:subPath?/:subPath2?/:subPath3?',
				name: 'DynamicPage',
				props: true,
				component: () => import('@/views/template/DynamicPage')
			}
		]
	},
	{
		path: '*',
		component: () => import('@/views/template/Index'),
		children: [
			{
				name: '404 Error',
				path: '',
				component: () => import('@/views/template/Error'),
			},
		],
	},
];

module.exports = routes;
